import React, { useRef } from 'react';
import './UnitDetail.css';

const UnitDetail = () => {
    const iframeRef = useRef(null);

    const handleFullscreen = () => {
        const iframeElement = iframeRef.current;
        if (iframeElement.requestFullscreen) {
            iframeElement.requestFullscreen();
        } else if (iframeElement.mozRequestFullScreen) { // Firefox
            iframeElement.mozRequestFullScreen();
        } else if (iframeElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
            iframeElement.webkitRequestFullscreen();
        } else if (iframeElement.msRequestFullscreen) { // IE/Edge
            iframeElement.msRequestFullscreen();
        }
    };

    return (
        <div className="unit-detail-page">
            <h2>Unit 1: Introduction to Accent Aura</h2>
            <div className="iframe-wrapper">
                <iframe
                    ref={iframeRef}
                    src="https://contentest.accentaura.com/unit1"
                    width="100%"
                    height="600px"
                    allow="fullscreen" 
                    frameBorder="0"
                    title="SCORM Package Content"
                />
            </div>
            <button onClick={handleFullscreen} className="fullscreen-button">
                Open Fullscreen
            </button>
        </div>
    );
};

export default UnitDetail;
