import React, { useState } from 'react';
import './StudentTable.css';
import { supabase } from '../../config/supabaseClient';

function StudentTable() {
    const [unit, setUnit] = useState('');
    const [date, setDate] = useState('');
    const [slot, setSlot] = useState('');
    const [students, setStudents] = useState([
        { slNo: 1, name: 'Akash Kumar', courseEnrolled: 'A1', attendance: '' },
        { slNo: 2, name: 'Ashwini Kumar', courseEnrolled: 'A1', attendance: '' },
        { slNo: 3, name: 'Saikat Chowdhury', courseEnrolled: 'A1', attendance: '' },
    ]);

    const handleAttendanceChange = (index, value) => {
        const updatedStudents = [...students];
        updatedStudents[index].attendance = value;
        setStudents(updatedStudents);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Creating an array of student data with attendance
        const attendanceData = students.map((student) => ({
            slNo: student.slNo,
            name: student.name,
            courseEnrolled: student.courseEnrolled,
            attendance: student.attendance,
        }));

        const payload = {
            unit,
            date,
            slot,
            attendanceData
        };

        console.log(payload); // This would send to Supabase

        try {
            const { data, error } = await supabase
                .from('attendance_table') // Replace with your actual table name
                .insert([payload]);

            if (error) {
                console.error("Error storing data:", error);
            } else {
                console.log("Attendance saved:", data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className='attendance-form-container'>
            <h1>Attendance Form</h1>
            <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label htmlFor="unit">Select Unit:</label>
                    <select id="unit" value={unit} onChange={(e) => setUnit(e.target.value)}>
                        <option value="">Select Unit</option>
                        <option value="Unit 1">Unit 1</option>
                        <option value="Unit 2">Unit 2</option>
                        <option value="Unit 3">Unit 3</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="date">Select Date:</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="slot">Select Slot:</label>
                    <select id="slot" value={slot} onChange={(e) => setSlot(e.target.value)}>
                        <option value="">Select Slot</option>
                        <option value="9 AM - 10 AM">9 AM - 10 AM</option>
                        <option value="10 AM - 11 AM">10 AM - 11 AM</option>
                        <option value="11 AM - 12 PM">11 AM - 12 PM</option>
                    </select>
                </div>




                <table className='student-table'>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Name</th>
                            <th>Course Enrolled</th>
                            <th>Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student, index) => (
                            <tr key={student.slNo}>
                                <td>{student.slNo}</td>
                                <td>{student.name}</td>
                                <td>{student.courseEnrolled}</td>
                                <td>
                                    <select
                                        value={student.attendance}
                                        onChange={(e) => handleAttendanceChange(index, e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Present">Present</option>
                                        <option value="Absent">Absent</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
    );
}

export default StudentTable;
