import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2'; // SweetAlert2 for notifications
import './ProfileSection.css';
import { useForm } from '../../hooks/useForm';
import { resetPassword } from '../../hooks/apiResetPassword';

const ResetPassword = () => {
  const initialState = { currentPassword: '', newPassword: '', confirmPassword: '' };
  const [formErrors, setFormErrors] = useState({});
  
  const { formData, handleChange, handleSubmit, error } = useForm(initialState, async () => {
    const confirmation = await Swal.fire({
      title: 'Confirm Password Reset',
      text: 'Are you sure you want to update your password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    });

    if (confirmation.isConfirmed) {
      await resetPassword(formData);
      Swal.fire('Updated!', 'Your password has been updated.', 'success');
    } else {
      Swal.fire('Cancelled', 'Your password update was cancelled.', 'error');
    }
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const validatePassword = () => {
    const errors = {};

    if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    if (!passwordRegex.test(formData.newPassword)) {
      errors.newPassword = 'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  
  const customHandleSubmit = async (e) => {
    e.preventDefault();
    if (validatePassword()) {
      handleSubmit(e); 
    }
  };

  return (
    <div className="reset-password-card">
      <h3 className="reset-heading">Reset Password</h3>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={customHandleSubmit}>
        {['currentPassword', 'newPassword', 'confirmPassword'].map((field) => (
          <div className="reset-input" key={field}>
            <label>{field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}:</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword[field] ? 'text' : 'password'}
                name={field}
                value={formData[field]}
                onChange={handleChange}
                required
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility(field)}
              >
                {showPassword[field] ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {formErrors[field] && <p className="error-message">{formErrors[field]}</p>}
          </div>
        ))}
        {/* <p className="password-rules">
          Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.
        </p> */}
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
