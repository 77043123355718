import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarComponent.css";
import Swal from "sweetalert2";

const localizer = momentLocalizer(moment);

const CalendarComponent = ({ upcomingClasses = [] }) => {


  function handleDragStart(event) {
    
  }
  // Trigger notification for an event starting in 5 minutes
  const triggerNotification = (event) => {
    if (Notification.permission === "granted") {
      new Notification(`Upcoming Class Reminder`, {
        body: `${event.title} is starting in 5 minutes!`,
      });
    }

    Swal.fire({
      title: "Upcoming Class Reminder",
      text: `${event.title} is starting in 5 minutes!`,
      icon: "info",
      showConfirmButton: true,
      confirmButtonText: "Got it",
      timer: 7000,
      timerProgressBar: true,
    });
  };

  const generateEvents = () => {
    let allEvents = [];

    upcomingClasses.forEach((classItem) => {
      const { course, start_date, end_date, start_time, class_type, _id } = classItem;
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);

      const startTime = new Date(`${start_date}T${start_time}`);
      const classDuration = 30;

      // setting start_time on startDate
      for (
        let day = startDate;
        day <= endDate;
        day.setDate(day.getDate() + 1)
      ) {
        // Class start time.
        const eventStart = new Date(day);
        eventStart.setHours(startTime.getHours(), startTime.getMinutes(), 0);

        // class will end by eventEnd. classStratTime + classDuration.
        const eventEnd = new Date(eventStart);
        eventEnd.setMinutes(eventEnd.getMinutes() + classDuration);

        // all events creation
        allEvents.push({
          id: _id,
          title: course,
          start: eventStart,
          end: eventEnd,
          description: class_type,
        });
      }
    });

    return allEvents;
  };

  const events = generateEvents();

  console.log(events, "events");

  useEffect(() => {
    const checkForUpcomingEvents = () => {
      const currentTime = new Date();

      events.forEach((event) => {
        const eventTime = new Date(event.start);
        const timeDifference = eventTime - currentTime;

        if (timeDifference > 0 && timeDifference <= 300000) {
          setTimeout(() => {
            triggerNotification(event);
          }, timeDifference - 300000);
        }
      });
    };

    if (events.length) {
      checkForUpcomingEvents();
    }
  }, [events]);

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%", width: "100%" }}
        popup
        handleDragStart={handleDragStart}
      />
    </div>
  );
};

export default CalendarComponent;