import { useMutation } from "@tanstack/react-query";
import { createAssignment } from "../hooks/apiAssignment";

export function useAssignment() {
    const { mutate: assignment, isLoading } = useMutation({
      mutationFn: createAssignment,
      onSuccess: () => {
        alert("Assignment created successfully");
    }
      
    });
  
    return { assignment, isLoading };
  }
