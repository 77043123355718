import React, { useState } from 'react';
import './LoginForm.css';
import { useLogin } from './useLogin';
import logo from '../../assets/images/logo-main.png'
import SpinnerMini from '../Spinner/SpinnerMini';

function LoginForm() {
  const {login,isLoading} = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = { email: '', password: '' };

   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'Invalid email format';
      formIsValid = false;
    }

    
    if (!password) {
      newErrors.password = 'Password is required';
      formIsValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  function handleSubmit(event) {
    event.preventDefault(); 
    if (validateForm()) {
     
      login({email,password});
      // console.log('Form submitted',email,password);
      
    } else {
      console.log('Validation failed');
    }
  };

  return (
    <div className='login-form-container'>
      <div className="welcome-message">
        {/* <h1>Welcome to Accent Aura Trainer App</h1> */}
        <img style={{width:'40%'}} src={logo} alt="logo" />
        <p>Please login to access your dashboard</p>
      </div>
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {errors.email && <span className="error">{errors.email}</span>}
          
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errors.password && <span className="error">{errors.password}</span>}
          
          <button type="submit" disabled={isLoading}>{ !isLoading ? 'Login' : <SpinnerMini />}</button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
