import React from 'react'
import './AssignmentViewDetails.css'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import AssignmentViewDetailsInner from '../../components/AssignmentViewDetailsInner/AssignmentViewDetailsInner'

function AssignmentViewDetails() {
  return (
    <div style={{display:'flex'}} className='assignment-view-details-container'>
          <Sidebar />
          <AssignmentViewDetailsInner />
    </div>
  
  )
}

export default AssignmentViewDetails