import React from 'react'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import ProfileSection from '../../components/ProfileSection/ProfileSection'

const ProfileSectionPage = () => {
  return (
    <div style={{display:'flex'}} className="ProfileSectionPage">
        <Sidebar />
        <ProfileSection />
    </div>
  )
}

export default ProfileSectionPage
