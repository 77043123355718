import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import {
  FaEdit,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import "./ProfileSection.css";
import Swal from "sweetalert2";
import ResetPassword from "./ResetPassword";
import { getCurrentUser } from "../../config/restApiAuth";

Modal.setAppElement("#root");

const ProfileSection = () => {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getCurrentUser();
        setUsername(userData.user.name);
        setRole(userData.user.role);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    userName: "John Doe",
    role: "Trainer",
    phone: "+123456789",
    email: "johndoe@example.com",
    address: "123 Main St, City, Country",
    occupation: "Teacher",
    dateOfBirth: "1990-01-01",
    facebook: "https://facebook.com",
    twitter: "https://twitter.com",
    linkedin: "https://linkedin.com",
    instagram: "https://instagram.com",
    profileImage: null,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.userName) newErrors.userName = "User Name is required";
    if (!formData.email.includes("@")) newErrors.email = "Invalid email";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.address) newErrors.address = "Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!validateForm()) return;

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData({ ...formData });
        setShowModal(false);
        Swal.fire("Saved!", "Your profile has been updated.", "success");
      }
    });
  };

  return (
    <div className="profile-container">
      <div className="profile-banner">
        <div className="banner-background"></div>

        <div className="user-info">
          <div className="user-info-detail">
            <h2 className="user-name">Hello, {username}</h2>
            <p className="user-role">{role}</p>
          </div>
        </div>
        <div className="edit-icon" onClick={() => setShowModal(true)}>
          <FaEdit size={24} />
        </div>
        <div className="social-media-icons">
          <a href={formData.facebook} target="_blank" rel="noopener noreferrer">
            <FaFacebook size={20} />
          </a>
          <a href={formData.twitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter size={20} />
          </a>
          <a href={formData.linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={20} />
          </a>
          <a
            href={formData.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={20} />
          </a>
        </div>
      </div>

      <div className="details-section">
        {/* User details card */}
        <div className="user-details-card">
          <h3 className="user-details-heading">
            <strong>Personal Information</strong>
          </h3>
          <div className="user-details-content">
            <div className="user-detail-item">
              <strong>Phone:</strong> <span>{formData.phone}</span>
            </div>
            <div className="user-detail-item">
              <strong>Email:</strong> <span>{formData.email}</span>
            </div>
            <div className="user-detail-item">
              <strong>Address:</strong> <span>{formData.address}</span>
            </div>
            <div className="user-detail-item">
              <strong>Occupation:</strong> <span>{formData.occupation}</span>
            </div>
            <div className="user-detail-item">
              <strong>Date of Birth:</strong>{" "}
              <span>{formData.dateOfBirth}</span>
            </div>
          </div>
        </div>

        {/* Modal for editing profile */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Edit Profile</h3>
              <form className="modal-form">
                {/* Profile Picture Dropzone */}

                <div className="form-group1">
                  <div className="form-group">
                    <label>Email:</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={errors.email ? "input-error" : ""}
                    />
                    {errors.email && (
                      <span className="error-text">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Phone:</label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className={errors.phone ? "input-error" : ""}
                    />
                    {errors.phone && (
                      <span className="error-text">{errors.phone}</span>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={errors.address ? "input-error" : ""}
                  />
                  {errors.address && (
                    <span className="error-text">{errors.address}</span>
                  )}
                </div>

                <div className="sociallink1">
                  <div className="form-group">
                    <label>Facebook Link:</label>
                    <input
                      type="url"
                      name="facebook"
                      value={formData.facebook}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Twitter Link:</label>
                    <input
                      type="url"
                      name="twitter"
                      value={formData.twitter}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="sociallink2">
                  <div className="form-group">
                    <label>LinkedIn Link:</label>
                    <input
                      type="url"
                      name="linkedin"
                      value={formData.linkedin}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Instagram Link:</label>
                    <input
                      type="url"
                      name="instagram"
                      value={formData.instagram}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="modal-actions">
                  <button
                    type="button"
                    onClick={handleSave}
                    className="save-btn"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <ResetPassword />
      </div>

      {/* Stats section */}
      <div className="stats-section">
        <div className="small-card">
          <h4>Courses Assigned</h4>
          <p>5</p>
        </div>
        <div className="small-card">
          <h4>Students Assigned</h4>
          <p>120</p>
        </div>
        <div className="small-card">
          <h4>Classes Held</h4>
          <p>35</p>
        </div>
        <div className="small-card">
          <h4>Performance Rating</h4>
          <p>4.8/5</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
