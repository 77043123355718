import React from 'react'
import './AssignmentManagement.css'
import AssignmentManagementMain from '../../components/AssignmentManagementMain/AssignmentManagementMain'
import { Sidebar } from '../../components/Sidebar/Sidebar'


function AssignmentManagement() {
  return (
    <div style={{display:'flex'}} className='assignment-management-container'>
        <Sidebar />
        <AssignmentManagementMain />
    </div>
  )
}

export default AssignmentManagement