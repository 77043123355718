import React from 'react'
import './DiscussionPage.css'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import DiscussionMainComponent from '../../components/DiscussionMainComponent/DiscussionMainComponent'

function DiscussionPage() {
  return (
    <div style={{display:'flex'}} className="faq-page-container">
        <Sidebar />
        <DiscussionMainComponent />
    </div>
  )
}

export default DiscussionPage