import React from 'react';
import './UnitCards.css';
import unitThumbnail from '../../assets/images/unit-thumbnail.jpg';
import playButton from '../../assets/images/play-button.png';

const UnitCards = ({ unitNumber, unitTitle , onCardClick }) => {
    return (
        <div className='unit-card' onClick={onCardClick}>
            <div className="unit-card-img-container">
                <img src={unitThumbnail} alt="unit-thumbnail" />
                <div className="overlay">
                    <img src={playButton} alt="play-button" className="play-button" />
                </div>
            </div>
            <div className="unit-card-content">
                <div className="unit-card-content-title">
                    <p>{unitTitle}</p>
                </div>
            </div>
        </div>
    );
};

export default UnitCards;
