import React, { useState } from 'react';
import './DiscussionMainComponent.css';
import QuestionCard from '../QuestionCard/QuestionCard';
import questionsData from '../questionsData';

function DiscussionMainComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState(questionsData);
  const [newQuestion, setNewQuestion] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); 
  const [name, setName] = useState('');
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleQuestionSubmit = () => {
    if (newQuestion.trim()) {
      setQuestions([...questions, { name: name, question: newQuestion }]);
      setNewQuestion('');
      handleModalToggle(); // close modal after submission
    }
  };
  const filteredQuestions = questionsData.filter((q) =>
    q.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-main-section">
      <div className="faq-main-container">
        <div className="heading-bell-container">
          <p style={{fontSize:'1.5rem'}} className="student-welcome-heading">Discussion Forum</p>
          <div className="profile-notification-container">
            <p className="bell-icon material-symbols-outlined">notifications</p>
            <p className="profile-icon material-symbols-outlined">account_circle</p>
          </div>
        </div>

        <div className="discussion-main-container">
          <div className="discussion-main-content">
            <div className="search-bar-ask-question-button-container">
            <div className="discussion-search-bar-container">
               
                <input 
                  type="text" 
                  placeholder="Search..." 
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} 
                />
              </div>
              <div className="ask-question-button-container">
                <button className="ask-question-button" onClick={handleModalToggle}>
                  Ask Question
                </button>
              </div>
            </div>

            <div className="discussion-question-card-container">
              {filteredQuestions.length > 0 ? (
                filteredQuestions.map((q, index) => (
                  <QuestionCard key={index} name={q.name} question={q.question} questionId={q.id} />
                ))
              ) : (
                <p>No questions found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for asking a question */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Ask a Question</h3>
            <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
            />
            <textarea
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              placeholder="Type your question here..."
            />
            <div className="modal-buttons">
              <button className="submit-button" onClick={handleQuestionSubmit}>
                Submit
              </button>
              <button className="close-button" onClick={handleModalToggle}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscussionMainComponent;
