import React, { useState } from 'react';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import './AssignedCourseDetails.css';
import UnitCardContainer from '../../components/UnitCardContainer/UnitCardContainer';
import StudentTableContainer from '../../components/StudentTableContainer/StudentTableContainer';

function AssignedCourseDetails() {
  // State to track the active tab (Units or Students Enrolled)
  const [activeTab, setActiveTab] = useState('units');

  return (
    <div style={{ display: 'flex' }} className='assigned-course-details-section'>
      <Sidebar />
      <div className="assigned-course-details-container">
        <div className="assigned-course-details-header-tab-container">
          <p>Course Details</p>
          <div className="assigned-course-details-tab">
            <button
              className={activeTab === 'units' ? 'active-tab' : ''}
              onClick={() => setActiveTab('units')}
            >
              Unit Details
            </button>
            <button
              className={activeTab === 'students' ? 'active-tab' : ''}
              onClick={() => setActiveTab('students')}
            >
              Students Enrolled
            </button>
          </div>
        </div>
        <div className="assigned-course-details-cards-container">
          {activeTab === 'units' ? (
            <><div className="assigned-course-details-about-course">
              
            </div><UnitCardContainer /></>
          ) : (
            <StudentTableContainer />
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignedCourseDetails;
