import React from 'react';
import './AssignmentLibraryCard.css';
import { useNavigate } from 'react-router-dom';

function AssignmentLibraryCard({ assignment }) {
  const navigate = useNavigate();
  
  return (
    <div className='assignment-library-card'>
        <div className='assignment-library-card-container'>
          <div className='assignment-library-card-header'>
              <p>{assignment.title}</p>
          </div>
          <div className='assignment-library-card-body'>
              <p>{assignment.description}</p>
              <button onClick={() => navigate("/assignmentview")} className='view-assignment-button'>View Assignment</button>
          </div>
        </div>
    </div>
  );
}

export default AssignmentLibraryCard;
