import React from 'react'
import StudentTable from '../StudentTable/StudentTable'

function StudentTableContainer() {
  return (
    <div className="student-table-section">
      <StudentTable />
    </div>
  )
}

export default StudentTableContainer