import React from "react";
import "./DashboardCards.css";
import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function DashboardCards() {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", flexDirection: "column", }} className="dashboard-cards-container">
      <div style={{ display: "flex", gap: "20px" }} className="card-section-1">
        <div className="card-item" onClick={() => navigate("/students")}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Assigned Students
              </Typography>
              <Typography variant="h2" className="card-count">
                1
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div
          className="card-item"
          onClick={() => alert("Classes Taught clicked!")}
        >
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Classes Taught
              </Typography>
              <Typography variant="h2" className="card-count">
                0
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* <div className="card-section-2">
        <div
          className="card-item"
          onClick={() => navigate("/assignmentmanagement")}
        >
          <Card className="dashboard-card create-assignment">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Assign a Task
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div> */}
    </div>
  );
}

export default DashboardCards;
