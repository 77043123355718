import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { userLogin } from '../../config/restApiAuth';
import { toast } from 'react-hot-toast'

export function useLogin() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutate: login, isLoading } = useMutation({
        mutationFn: ({ email, password }) =>
            userLogin({ email, password })
        ,
        onSuccess: (user) => {
            queryClient.setQueryData(["user"], user);
            navigate('/trainerdashboard')
            if (!user.success) { toast.error("Invalid credentials"); }
            else { toast.success("Login Successful"); }
        },
        onError: (error) => {
            // console.log(error);

        }
    });

    return { login, isLoading };
}