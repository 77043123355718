import React from 'react'
import './LoginPage.css'
import LoginForm from '../../components/LoginForm/LoginForm'

function LoginPage() {
    return (
        <div className='login-form-section'>
            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>
            <LoginForm />
        </div>
    )
}

export default LoginPage