import React from 'react';
import './AssignmentSearchBar.css';

function AssignmentSearchBar({ onSearch }) {
  return (
    <input
      type="text"
      className="assignment-search-input"
      placeholder="Search Assignments..."
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}

export default AssignmentSearchBar;
