import React, { useState } from 'react';
// import './Ellipsis.css';

const Ellipsis = ({ text, maxLength = 25 }) => {
  const [isExpanded, setIsExpanded] = useState(false);


  if (text?.length <= maxLength) {
    return <span>{text}</span>; 
  }

  return (
    <span>
      {isExpanded ? text : `${text?.substring(0, maxLength)}...`}
    </span>
  );
};

export default Ellipsis;
