import React from 'react';
import './CreateAssignmentBanner.css';

function CreateAssignmentBanner({ onClick }) {
  return (
    <div className="assignment-banner" onClick={onClick}>
      <h2>Create a New Assignment</h2>
      <button className="create-assignment-button">Create Assignment</button>
    </div>
  );
}

export default CreateAssignmentBanner;
