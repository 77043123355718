import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, TablePagination, Tooltip, Typography, Tabs, Tab, Box
} from "@mui/material";
import { motion } from "framer-motion";
import "./StudentProfile.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { useCurrentTrainer } from "../../hooks/useCurrentTrainer"
import {useStudents} from "../../components/LoginForm/useTrainer"
import { useQueryClient } from "@tanstack/react-query";

const StudentProfile = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [activeTab, setActiveTab] = useState(0);
  const {
    isLoading: isTrainerLoading,
    schedules,
  } = useCurrentTrainer();

 const scheduleIndividualType = schedules?.filter((s) => s.class_type === "Individual" && s.is_booked);
 const studentIndividualArray = scheduleIndividualType?.map((s) => s.students).flat();
 const studentIndividualArrayUnique = [ ...new Set(studentIndividualArray)];
 console.log(studentIndividualArrayUnique, "Individual Students");

 const queryClient = useQueryClient();

 queryClient.setQueryData(["IndividualStudents"], studentIndividualArrayUnique);
 const { isLoading: isStudentsLoading, assignedStudents } = useStudents();
 
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0); 
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Filter students based on search term and active tab (individual or group)
  const filteredStudents = students
    .filter((student) => {
      if (activeTab === 0 && student.classType !== "individual") return false;
      if (activeTab === 1 && student.classType !== "group") return false;
      return student.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.course?.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div style={{ display: "flex" }} className="StudentProfileSection">
      <Sidebar />
      <div className="student-profile-container" style={{ width: "100%", padding: "20px", height: "110vh" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", margin: "0" }} className="Profile_Header">
          <Typography variant="h5" className="dynamic-title" sx={{ fontWeight: "800", fontFamily: "'Nunito Sans', sans-serif", color: "black"  }}>
            Assigned Students
          </Typography>

          {/* Tabs for Individual and Group */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="student type tabs">
              <Tab label="Individual" />
              <Tab label="Group" />
            </Tabs>
          </Box>

          <motion.input
            type="text"
            placeholder="Search by name or course..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
            whileFocus={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          />
        </div>

        <motion.div
          className="table-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <TableContainer component={Paper} className="table-paper">
            <Table aria-label="students table">
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStudents
                  .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                  .map((student) => (
                    <TableRow key={student.id} hover>
                      <TableCell>
                        <Tooltip title={student.name} arrow>
                          <span>{student.name || "No Name"}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{student.course || "No Course"}</TableCell>
                      <TableCell>
                        <div className="progress-bar">
                          <div
                            className="progress"
                            style={{ width: `${student.progress || 0}%` }}
                          ></div>
                        </div>
                        <span>{student.progress || 0}%</span>
                      </TableCell>
                      <TableCell align="center">
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="action-buttons"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => console.log("Assigned Tasks for", student.name)}
                            sx={{ marginRight: "10px" }}
                          >
                            Assigned Tasks
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => console.log("Profile of", student.name)}
                          >
                            Profile
                          </Button>
                        </motion.div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredStudents.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[6]}
            className="pagination"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default StudentProfile;
