import React from 'react';
import './Pagination.css';

function Pagination({ totalCards, cardsPerPage, setCurrentPage }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination-nav">
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item" onClick={() => setCurrentPage(number)}>
            {number}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
