import React, { useState, useEffect } from 'react'
import './AssignedCourse.css'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import AssignedCourseCard from '../../components/AssignedCourseCard/AssignedCourseCard'
import { supabase } from '../../config/supabaseClient' // Ensure this is correctly set up

function AssignedCourse() {
  const [courses, setCourses] = useState([]);  // State for storing fetched courses

  // Fetch courses data from supabase
  useEffect(() => {
    const fetchCourses = async () => {
      let { data, error } = await supabase
        .from('courses')
        .select('*');

      if (error) {
        console.log("Error fetching courses:", error);
      } else {
        setCourses(data);  // Set the data in state
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className='assigned-course-section'>
      <Sidebar />
      <div className="assigned-course-container">
        <div className="assigned-course-container-heading heading-bell-container">
          <h1>Courses Assigned</h1>
          <div className="profile-notification-container">
            <p className="bell-icon material-symbols-outlined">notifications</p>
          </div>
        </div>
        <div className="assigned-course-card-container">
          {courses.map(course => (
            <AssignedCourseCard
              key={course.id}
              id={course.id}
              title={course.title}
              description={course.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssignedCourse;
