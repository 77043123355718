import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCurrentUser, getAssignedStudents } from "../../config/restApiAuth";

export function useTrainer() {
  const { isLoading, data: user } = useQuery({
    queryKey: ["user"],
    queryFn: getCurrentUser,
    staleTime: 0,
  });

  return { isLoading, user, isAuthenticated: user?.user?.role === "trainer" };
}


export function useStudents(){
  const queryClient = useQueryClient();
  const individualStudents = queryClient.getQueryData(["IndividualStudents"]);
  const { isLoading, data: assignedStudents } = useQuery({
    queryKey: ["students"],
    queryFn: () => getAssignedStudents({students : individualStudents}),
    staleTime: 0,
  });

  return { isLoading, assignedStudents };
}
