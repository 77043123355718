import { API_BASE_URL } from "../config/constant.js";

export async function resetPassword({ currentPassword, newPassword, confirmPassword }) {
    try {
        console.log("Old Password:", currentPassword, "New Password:", newPassword, "Confirm Password:", confirmPassword);
        
        const uri = `${API_BASE_URL}password/update`; 
        const response = await fetch(uri, {
            method: "PUT", 
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                currentPassword,
                newPassword,
                confirmPassword
            }),
            credentials: "include",  
        });

        const data = await response.json();
        console.log(data);

        return data;
    } catch (error) {
        console.error("Error updating password:", error.message);
        throw new Error(error.message);
    }
}
