import React, { useState } from 'react';
import './AssignmentManagementMain.css';
import CreateAssignmentBanner from '../CreateAssignmentBanner/CreateAssignmentBanner';
import AssignmentLibraryCard from '../AssignmentLibraryCard/AssignmentLibraryCard';
import Pagination from '../Pagination/Pagination';
import AssignmentSearchBar from '../AssignmentSearchBar/AssignmentSearchBar';
import CreateAssignmentModal from '../CreateAssignmentModal/CreateAssignmentModal';

function AssignmentManagementMain() {
  const [assignments, setAssignments] = useState([
    { id: 1, title: 'English Grammar 1', description: 'Fill in the blanks' },
    { id: 2, title: 'Speaking', description: 'Make a video by speaking' },
    { id: 3, title: 'English Grammar 1', description: 'Fill in the blanks' },
    { id: 4, title: 'Speaking', description: 'Make a video by speaking' },
    { id: 5, title: 'English Grammar 1', description: 'Fill in the blanks' },
    { id: 6, title: 'Speaking', description: 'Make a video by speaking' },
    { id: 7, title: 'English Grammar 1', description: 'Fill in the blanks' },
    { id: 8, title: 'Speaking', description: 'Make a video by speaking' },
    // Add more assignments here...
  ]);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const cardsPerPage = 6;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;

  // Filter assignments based on search term
  const filteredAssignments = assignments.filter((assignment) =>
    assignment.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Get current assignments based on pagination
  const currentAssignments = filteredAssignments.slice(indexOfFirstCard, indexOfLastCard);

  return (
    <div className='assignment-management-main-section'>
      <div className='assignment-management-main-container'>
        <div className='assignment-management-main-heading'>
          <p>Assignment Management</p>
          <div className="profile-notification-container">
            {/* <span className="profile-icon material-symbols-outlined">
              account_circle
            </span> */}
            <p className="bell-icon material-symbols-outlined">notifications</p>
          </div>
        </div>
        
        {/* Create Assignment Banner */}
        <div className='assignment-management-main-create-banner'>
            <CreateAssignmentBanner onClick={openModal} />
        </div>

        <CreateAssignmentModal isOpen={isModalOpen} onClose={closeModal} />
        
        {/* Search Bar */}
        <div className="assignment-management-main-search-bar">
          <AssignmentSearchBar onSearch={setSearchTerm} />
        </div>

        {/* Assignment Library */}
        <div className='assignment-management-main-assignment-library'>
          <div className='assignment-management-main-assignment-library-heading'>
            <p>Assignment Library</p>
          </div>
          
          {/* Assignment Cards */}
          <div className='assignment-management-main-assignment-library-card-container'>
            {currentAssignments.length > 0 ? currentAssignments.map((assignment) => (
              <AssignmentLibraryCard key={assignment.id} assignment={assignment} />
            )) : <p>No Assignments Found</p>}
          </div>

          {/* Pagination */}
          <Pagination 
            totalCards={filteredAssignments.length} 
            cardsPerPage={cardsPerPage} 
            setCurrentPage={setCurrentPage} 
          />
        </div>
      </div>
    </div>
  );
}

export default AssignmentManagementMain;
